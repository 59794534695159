import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import {
  isUserSiteAdmin,
  checkUserAffiliationForPermissions,
} from "../../shared/utilities";
import { getContactAffiliationByContactID } from "../../../services/affiliation-service";
import { useEffect, useState } from "react";

export const NavBarTabs = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [userAffiliation, setUserAffiliation] = useState(null);
  const isAdmin = isUserSiteAdmin(user);

  // const hasContentPermission = checkUserAffiliationForPermissions(
  //   userAffiliation,
  //   ["content.view"]
  // );
  // const hasContactPermission = checkUserAffiliationForPermissions(
  //   userAffiliation,
  //   ["contacts.view"]
  // );

  //TODO: Setting this to true for now until we can get confirmation on the permissions from Diana & Co
  const hasContentPermission = true;
  const hasContactPermission = true;

  useEffect(() => {
    async function fetchAffiliation() {
      const accessToken = await getAccessTokenSilently();
      var contactID = user?.memberId ?? null;
      const payload = contactID;
      const affiliationReturned = await getContactAffiliationByContactID(
        accessToken,
        payload
      );
      setUserAffiliation(affiliationReturned.data);
    }
    fetchAffiliation();
  }, []);

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/" label="Home" />
      {isAuthenticated && (
        <>
          <NavBarTab path="/profile" label="Profile" />
          {hasContactPermission && (
            <NavBarTab path="/contacts/all" label="Contacts" />
          )}
          {hasContentPermission && (
            <NavBarTab path="/content/all" label="Content" />
          )}
          {isAdmin && <NavBarTab path="/admin" label="Admin" />}
        </>
      )}
    </div>
  );
};
