import React from "react";
import { PageLayout } from "../components/page-layout";
import ContentCreateEdit from "../components/content/content-create-edit";
import ContentList from "../components/content/content-list";
import { useAuth0 } from "@auth0/auth0-react";
import { GuestHome } from "../components/home/guest-home";
import HelpfulLinks from "../components/home/helpful-links";
import PinnedPosts from "../components/pinned-posts/pinned-post";

export const HomePage = () => {
  const { isAuthenticated, user } = useAuth0();
 
  return (
    <PageLayout fullWidth={true}>
      {isAuthenticated && 
        <>
          <PinnedPosts />
          <ContentList />
          <HelpfulLinks user={user}/>
        </>
      }
      {!isAuthenticated && 
        <GuestHome />
      }
    </PageLayout> 
  )
};
