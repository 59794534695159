import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { isUserSiteAdmin, checkUserAffiliationForPermissions } from "../../shared/utilities";
import { getContactAffiliationByContactID } from "../../../services/affiliation-service";
import { useEffect, useState } from "react";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const isAdmin = isUserSiteAdmin(user);
  const [userAffiliation, setUserAffiliation] = useState(null);
  // const hasContentPermission = checkUserAffiliationForPermissions(userAffiliation, ["content.view"]);
  // const hasContactPermission = checkUserAffiliationForPermissions(userAffiliation, ["contacts.view"]);

    //TODO: Setting this to true for now until we can get confirmation on the permissions from Diana & Co
    const hasContentPermission = true;
    const hasContactPermission = true;
  

    useEffect(() => {

        async function fetchAffiliation() {
            const accessToken = await getAccessTokenSilently();
            var contactID = user?.memberId ?? null;
            const payload = contactID;
            const affiliationReturned = await getContactAffiliationByContactID(accessToken, payload);            
            setUserAffiliation(affiliationReturned.data);
        }
        fetchAffiliation();
    }, []);

  return (
    <div className="mobile-nav-bar__tabs">
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/profile"
            label="Profile"
            handleClick={handleClick}
          />

          {hasContentPermission &&
            <MobileNavBarTab path="/content/all" label="Content" />
          }

          {hasContactPermission &&
            <MobileNavBarTab path="/contacts/all" label="Contacts" />
          }

          { isAdmin &&
            <MobileNavBarTab
              path="/admin"
              label="Admin"
              handleClick={handleClick}
            />
          }
          <MobileNavBarTab
            path="/"
            label="Home"
          handleClick={handleClick}
      />
        </>
      )}
    </div>
  );
};
