import { useEffect, useState } from "react";
import React from "react";
import "../../styles/components/contact.css"

export default function ContentContactOption({contact, handleChangeCheckBox, showPhoneAndEmailAttrs = false, searchMatch = true}) {
    return (
        <div key={contact.id} className={`contact-email-selection contact-search-match-${searchMatch}`}>            
            <label className="emailCheckboxLable">
                <input
                    className="emailCheckbox"
                    type="checkbox"
                    onChange={(e) => handleChangeCheckBox(e)}
                    value={contact.checked}
                    checked={contact.checked}
                    data-contactid={contact.id}
                />
                <div className="content-checkbox-label">{contact?.firstName}</div>
                <div className="content-checkbox-label">{contact?.lastName}</div>
                {showPhoneAndEmailAttrs && <div className="contact-checkbox-attributes">
                    {contact.phone && <div>Email <i className="fa-solid fa-check"></i></div>}
                    {contact.email && <div>Phone <i className="fa-solid fa-check"></i></div>}
                </div>}
            </label>
        </div>                
    )
}