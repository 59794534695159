import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

  export const getContactAffiliationByContactID = async (accessToken, id) => {
    const config = {
      url: `${apiServerUrl}/api/contact/getContactAffiliationByContactID/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {}
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }