import React, { useState, useEffect } from "react";
import "../../styles/components/content.css";
import Loader from "../navigation/Loader";
import HelpfulLink from "./helpful-link";
import {
  checkForUserPermissions,
  checkUserAffiliationForPermissions,
} from "../shared/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { getContactAffiliationByContactID } from "../../services/affiliation-service";

export default function HelpfulLinks({ user }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userAffiliation, setUserAffiliation] = useState(null);

//   const hasContentPermission = checkUserAffiliationForPermissions(
//     userAffiliation,
//     ["content.view"]
//   );
//   const hasContactPermission = checkUserAffiliationForPermissions(
//     userAffiliation,
//     ["contacts.view"]
//   );

  //TODO: Setting this to true for now until we can get confirmation on the permissions from Diana & Co
  const hasContentPermission = true;
  const hasContactPermission = true;


  useEffect(() => {
    async function fetchAffiliation() {
      const accessToken = await getAccessTokenSilently();
      var contactID = user?.memberId ?? null;
      const payload = contactID;
      const affiliationReturned = await getContactAffiliationByContactID(
        accessToken,
        payload
      );
      setUserAffiliation(affiliationReturned.data);
    }
    fetchAffiliation();
  }, []);

  return (
    <div className="helpful-links">
      <div className="content-list-title">Helpful Links</div>
      {hasContactPermission && (
        <HelpfulLink
          linkText="Members"
          linkUrl="/contacts/all"
          icon={<i className="fa-solid fa-people-simple"></i>}
        />
      )}

      <HelpfulLink
        linkText="Profile"
        linkUrl="/profile"
        icon={<i className="fa-solid fa-person-dress-simple"></i>}
      />
      {hasContentPermission && (
        <HelpfulLink
          linkText="Content"
          linkUrl="/content/all"
          icon={<i className="fa-solid fa-shapes"></i>}
        />
      )}

      {/* {checkForUserPermissions(["view:jerseyform", "site:admin", "IT:admin"], user) && 
            <HelpfulLink 
                linkText="Jersey Request"
                linkUrl="/jerseyform"
                icon={<i className="fa-solid fa-shirt"></i>}
            />} */}
      {checkForUserPermissions(
        ["view:jerseyform", "site:admin", "IT:admin"],
        user
      ) && (
        <HelpfulLink
          linkText="Season Gift Form"
          linkUrl="/giftform"
          icon={<i className="fa-solid fa-gift"></i>}
        />
      )}
    </div>
  );
}
