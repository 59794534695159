import React, { useState, useRef  } from "react";
import { PageLayout } from "../components/page-layout";
import { AdminButton } from "../components/buttons/admin-button";
import "../styles/admin.css";
import { useAuth0 } from "@auth0/auth0-react";
import { isUserITAdmin } from "../components/shared/utilities";
import { resetCache } from "../services/admin-service";
import { toast } from "react-hot-toast";
import Loader from "../components/navigation/Loader";
import { getAllContactsCSV } from "../services/contact-service";
import { CSVLink } from "react-csv";

export const AdminPage = () => {  
  const { getAccessTokenSilently, user } = useAuth0();
  const isITAdmin = isUserITAdmin(user);
  const [contactsCSV, setContactsCSV] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const csvLinkRef = useRef(null); 

  const handleRefreshCacheClick = (e) => {
    if (!isITAdmin) return;

    const clearCache = async () => {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await resetCache(token);            
      setLoading(false);
      if (result.error) {
        toast.error("error updating cache.");
      }
      else {
        toast.success("updated cache!");
      }
    }
    clearCache();
  }

  const handleDownloadClick = async () => {
    setCsvLoading(true);
    const token = await getAccessTokenSilently();
    const result = await getAllContactsCSV(token);
    setContactsCSV(result.data);
    setCsvLoading(false);
    
    setTimeout(() => {
      csvLinkRef.current.link.click();
    }, 0);
  };

  return (
    <PageLayout>
      <div className="admin-btn-panel">
          <div className="generic-title left">Admin Tools</div>
          <AdminButton icon={<i className="fa-duotone fa-shapes"></i>} buttonText="Add Content" clickHandler={(e) => window.location.href = "/content/new" } />
          <AdminButton icon={<i className="fa-solid fa-people-simple"></i>} buttonText="Add a Contact" clickHandler={ (e) => window.location.href = "/contacts/new" } />
          { isITAdmin &&
            <AdminButton icon={<i className="fa-regular fa-arrows-rotate"></i>} buttonText={loading ? <Loader /> : `Empty Cache`} clickHandler={handleRefreshCacheClick} />
          }
          <AdminButton icon={<i className="fa-solid fa-shirt"></i>} buttonText="Jersey Form Admin" clickHandler={ (e) => window.location.href = "/jerseyadmin" } />
          <AdminButton icon={<i className="fa-solid fa-message"></i>} buttonText="Send a Message" clickHandler={ (e) => window.location.href = "/message/send" } /> 
          <AdminButton icon={<i className="fa-solid fa-gift"></i>} buttonText="Gift Form Admin" clickHandler={ (e) => window.location.href = "/giftadmin" } />  
          {csvLoading ? <Loader /> :
            <>
              <AdminButton icon={<i className="fa-solid fa-download"></i>} buttonText="Download Contact CSV" clickHandler={handleDownloadClick} />
              <CSVLink data={contactsCSV} filename="contact-report.csv" ref={csvLinkRef} style={{ display: "none" }} />
            </>
          } 
      </div>
      
    </PageLayout>
  )
}
