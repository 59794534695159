import React, { useState, useEffect } from "react";
import { uploadContent, deleteContentById } from "../../services/content-service";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";
import toast from 'react-hot-toast';
import { CreateContactField } from "../contacts/create-contact-field";
import ContactCheckboxList from "./contact-checkbox-list";
import { getAllContacts } from "../../services/contact-service";
import { AreYouSureModal } from "../shared/are-you-sure";
import { getFileExtensionFromFileName } from "../shared/utilities";
import Select from "react-select";
import { contentCategories } from "../../utils/content-utils";


export default function ContentCreateEdit({content = null}) {
    const [file, uploadFile] = useState(null); 
    const [fileName, setFileName] = useState(initialContentState("description")); 
    const [fileType, setFileType] = useState(initialContentState("fileType"));
    const [tags, setTags] = useState(initialContentState("tags"));
    const [inputTag, changeTagInput] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [featureConfigs, setFeatureConfigs] = useState(initialContentState("featureConfigs"));
    const [isVisible, setisVisible] = useState(false);
    const [contactsLoading, setContactsLoading] = useState(false);
    const [contactsForEmail, setContactsForEmail] = useState(initialContentState("contactsForEmail"));
    const [showModal, setShowModal] = useState(false);
    const [contentCategory, setContentCategory] = useState(initialContentState("category"));

    const id = content?.id ?? null;
    const contactCountToSendEmail = contactsForEmail?.filter((element) => element.checked !== false)?.length;

    const editMode = content != null;

    function initialContentState(propName) {
        if (content) {
            if (propName === "featureConfigs") {
                return {
                    isFeatured: content["isFeatured"],
                    isBanner: content["isBanner"]
                }
            } else {
                return content[propName];
            } 
        }
        else {
            const initialStateNewContent = {
                id: null,
                file: null,
                fileType: null,
                description: "",
                tags: [],
                contactsForEmail: [],
                featureConfigs: {
                    isFeatured: false,
                    isBanner: false
                },
                category: ""
            }
            return initialStateNewContent[propName];
        }        
    }

    
    const { getAccessTokenSilently } = useAuth0();

    const resetState = () => {
        uploadFile(null);
        setFileName("");
        setTags([]);
        changeTagInput("");
        setSubmitting(false);
        setFeatureConfigs({isFeatured : false, isBanner: false});
        setisVisible(false);
    }

    useEffect(() => {
        const retrieveContacts = async () => {
            setContactsLoading(true);
            const token = await getAccessTokenSilently();
            const result = await getAllContacts(token);
            setContactsForEmail(result.data.map(c => 
                ({...c, checked: false})
            ));
            setContactsLoading(false);
        }
        retrieveContacts();
    }, [])

    const handleFileChange = (event) => {
        uploadFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        const fileExtension = getFileExtensionFromFileName(event.target.files[0].name);
        setFileType(fileExtension);
    }

    function handleOtherFieldChange(e) {
        const propertyName = e.target.name;
        const propertyValue = e.target.type == "checkbox" ? e.target.checked : e.target.value;
        const newFeatureConfigs = {
            ...featureConfigs
        };
        newFeatureConfigs[propertyName] = propertyValue;
        setFeatureConfigs(newFeatureConfigs);
    }

    const handleFileNameChange = (event) => setFileName(event.target.value);

    const handleChangeTagInput = (event) => changeTagInput(event.target.value);

    const handleAddTag = () => {
        setTags([...tags, inputTag]);
        changeTagInput("");
    }

    const handleTagListKeyUp = (event) => {
        if (event.key === "Enter") {
            handleAddTag();
        }
    }

    const sendFileClick = async function() {
        setSubmitting(true);

        const accessToken = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        formData.append("fileType", fileType);
        formData.append("tags", JSON.stringify(tags));
        formData.append("isBanner", featureConfigs.isBanner);
        formData.append("isFeatured", featureConfigs.isFeatured);
        formData.append("category", contentCategory);
        formData.append("emailList", JSON.stringify(contactsForEmail.filter(c => c.checked).map(c => c.email)));

        var uploadedcontent = await uploadContent(accessToken, formData, id);
        if (!uploadedcontent.error) {
            toast.success(`content ${id ? "updated" : "created"}!`);
            id && resetState();
        }
        else {
            toast.error("there was an issue!");
        }

        setSubmitting(false);
    }

    const deleteContent = async () => {
        setDeleting(true);
        const accessToken = await getAccessTokenSilently();
        let contentDeleted = await deleteContentById(accessToken, id); 
               
        if (!contentDeleted.error) {
            toast.success(`Content Deleted!`);
            resetState();
            window.location = '/Content/All';
        }
        else {
            toast.error(`Content deletion failed: ${contentDeleted.error.message}`);
        }

        setDeleting(false);
    } 
    
    const handleRemoveTagClick = (event) => {
        let tagIndex = event.target.parentElement.dataset.index;

        // remove the tag at the given index
        let newTags = [...tags];
        newTags.splice(tagIndex, 1);

        setTags(newTags);
    }

    function handleNotificationChange() {
        setisVisible(!isVisible);
        setContactsForEmail(contactsForEmail.map(c => 
            ({...c, checked: !isVisible})
        ));
    }

    const checkAllChangeHandler = (allChecked) => {
        const newContacts = [...contactsForEmail];
        setContactsForEmail(newContacts.map(c => ({...c, checked : allChecked})));
    }

    const handleChangeCheckBox = (e) => {
        const contactId = e.target.dataset.contactid
        let updatedContactList = contactsForEmail.map(c => c.id === contactId ? {...c, checked: !c.checked} : c);
        setContactsForEmail(updatedContactList);
    };

    const currentTagList = tags.map((t, i) => 
        <div data-index={i} key={i} className="searchTagToAdd tag-temp">
            {t}
            <i onClick={handleRemoveTagClick} className="fa-regular fa-xmark"></i>
        </div>
    )

    return (
        <div className="admin-panel-outer">
            <div className="admin-panel-header">{content ? "Edit" : "Add"} Content</div>
            <input onChange={handleFileChange} type="file" />
            <input className="content-upload-textinput" type="text" placeholder="content description" onChange={handleFileNameChange} value={fileName} />
            <div style={{display: "flex", gap: "10px", flexDirection: "column", marginBottom: "10px"}}>
                <label className="content-upload-label" style={{color: "gray"}}>Content Category</label>
                <Select 
                    options={contentCategories.map(c => ({value: c, label: c}))}
                    placeholder="Select category"
                    value={contentCategory ? {value: contentCategory, label: contentCategory} : null}
                    onChange={(val) => setContentCategory(val.value)}
                    style={{width: "100%"}}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'gray',
                            primary: 'gray',
                            neutral0: "#222222",
                            neutral5: "white",
                            neutral10: "white",
                            neutral20: "gray"
                        },
                    })}
                    styles={{
                        width: "100%",
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "white"
                        }),
                        multiValueRemove: (baseStyles) => ({
                            ...baseStyles,
                            color: "black",
                        })
                    }}
                />
            </div>
            <div className="content-upload-tags">
                <input className="content-upload-textinput" type="text" placeholder="add a search tag" value={inputTag} onKeyUp={handleTagListKeyUp} onChange={handleChangeTagInput} />
                <button className="btn btn2" style={{fontSize: "15px", padding: "5px 18px"}} onClick={handleAddTag}>Add tag <i className="fa-regular fa-plus"></i></button>
                <div className="content-tags-runninglist">
                    {currentTagList.length > 0 ? currentTagList : <div className="notags">No tags created.</div>}
                </div>
            </div>
            <CreateContactField 
                value={featureConfigs.isFeatured} 
                type="checkbox" 
                id="isFeaturedContentField" 
                handleValueChange={handleOtherFieldChange} 
                propName="isFeatured" 
                label="Feature this content on the front page?" 
                style={{justifyContent: "space-between", maxWidth: "unset"}}
            />
            <CreateContactField 
                value={featureConfigs.isBanner} 
                type="checkbox"
                id="isBannerContentField" 
                handleValueChange={handleOtherFieldChange} 
                propName="isBanner" 
                label="Make this item the front page banner?" 
                style={{justifyContent: "space-between", maxWidth: "unset"}}
            />
            <CreateContactField 
                type="checkbox"
                id="sendNotificationContentField" 
                handleValueChange={handleNotificationChange} 
                propName="sendNotification" 
                label="Notify Members" 
                style={{justifyContent: "space-between", maxWidth: "unset"}}
            />
            
            { isVisible && 
                <ContactCheckboxList 
                    contacts={contactsForEmail}
                    loading={contactsLoading}
                    handleChangeCheckBox={handleChangeCheckBox}
                    handleCheckAllChange={checkAllChangeHandler}
                />
            }

            {/* <button style={{width: "100%"}} className="btn btn1" onClick={sendFileClick}>
                {isSubmitting ? <Loader /> : <><span>Upload</span><i className="fa-regular fa-arrow-right"></i></> }
            </button> */}

            <div className="contact-update-delete-row" style={{width: "100%", marginTop:"12px"}}>
                {
                    editMode &&
                    <button id="deleteContactBtn" className="btn btnDelete" onClick={() => setShowModal(true)} >
                        <i className="fa-solid fa-trash"></i><span>Delete</span>
                    </button>
                }
              
                <button className="btn btn1" onClick={sendFileClick} >
                    {isSubmitting ? <Loader /> : <><span>Upload</span><i className="fa-regular fa-arrow-right"></i></> }
                </button>
            </div>

            <AreYouSureModal onCompleteAction={deleteContent} handleCancelAction={() => setShowModal(false)} loadingState={isDeleting} isVisible={showModal}/>

        </div>
    )
}