import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getContent,updateSortOrder } from "../../services/content-service";
import ContentItem from "./content-item";
import Loader from "../navigation/Loader";
import ContentBanner from "./content-banner";
import { checkUserAffiliationForPermissions, isUserSiteAdmin } from "../shared/utilities";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { getContactAffiliationByContactID } from "../../services/affiliation-service";

export default function ContentList({showAllContent = false}) {
    const [contentList, setContent] = useState([]); 
    const [isLoading, setLoaded] = useState(true);
    const { getAccessTokenSilently, user } = useAuth0();
    const isContentAdmin = isUserSiteAdmin(user);
    const [contentSubLists, setContentSubLists] = useState({featuredContent: [], allContentList : [], banner : null});
    const [userAffiliation, setUserAffiliation] = useState(null);

    const hasContentPermission = checkUserAffiliationForPermissions(userAffiliation, ["content.view"]);
   

    useEffect(() => {

        async function fetchAffiliation() {
            const accessToken = await getAccessTokenSilently();
            var contactID = user?.memberId ?? null;
            const payload = contactID;
            const affiliationReturned = await getContactAffiliationByContactID(accessToken, payload);            
            setUserAffiliation(affiliationReturned.data);
        }
        fetchAffiliation();

        async function fetchContent() {
            const accessToken = await getAccessTokenSilently();
            const payload = {};
            const contentReturned = await getContent(accessToken, payload);            
            setLoaded(false);
            setContent(contentReturned.data);
        }
        fetchContent();

    }, []);

    const featuredContent = contentList?.content?.filter(c => 
        c.isFeatured && !c.isBanner
    );

    const featuredContentDivs = featuredContent?.map(c =>
        <ContentItem canEdit={isContentAdmin} backgrounds={contentList.backgrounds} key={c.id} content={c}/>
    )
    
    const allContentList = contentList?.content?.sort((a,b) => a.sortOrder - b.sortOrder).map((c, i) => {
        return (
            <Draggable draggableId={c.id} key={c.id} index={i}>
             {(provided) => (
                <div {...provided.dragHandleProps}
                {...provided.draggableProps}
                ref={provided.innerRef}>
                    <ContentItem canEdit={isContentAdmin} backgrounds={contentList.backgrounds} key={c.id} content={c} />
                </div>
            )}    
            </Draggable>
        )
    });

    const handleDragDrop = (results) => {
        const {source, destination} = results;
        
        if(!destination) return;
        if(source.droppableId === destination.droppableId && source.index === destination.index) return;

        const reorderedContent = {...contentList};
        const sourceIndex = source.index;
        const destinationIndex = destination.index;

        const [removedContent] = reorderedContent.content.splice(sourceIndex, 1);
        reorderedContent.content.splice(destinationIndex, 0, removedContent);
        reorderedContent.content = reorderedContent.content.map((c, i) => {
            return {...c, sortOrder: i + 1}
        });
        
        const newSortOrder = reorderedContent.content.filter(c => 
            contentList.content.filter(d => d.id == c.id)[0].sortOrder != c.sortOrder
        ).map(c => 
            ({  
                id: c.id,
                sortOrder: c.sortOrder
            }));

        setContent(reorderedContent);

        async function updateOrder() {
            const accessToken = await getAccessTokenSilently();
            const payload = newSortOrder;
            const newOrder = await updateSortOrder(accessToken, payload);            
            setLoaded(false);
            if (newOrder.error) {
            toast.error("there was an issue changing the sort order!");
            }  
        }
        updateOrder();
    }; 
    
    const banner = contentList?.content?.filter(c => c.isBanner)[0] ?? null;

    if(!hasContentPermission) {
        return (
            <div className="content-list">
                <div className="content-list-title">Documents & Resources</div>
                <div className="no-content">You do not have permission to view this content</div>
            </div>
        )
    }

    if (showAllContent) {
        return (          
        <div className="content-list">
            <div className="content-list-title">Documents & Resources</div>
            <DragDropContext onDragEnd={handleDragDrop}> 
                <Droppable droppableId="ROOT" type="group">
                 {(provided) => (     
                    <div className="content-list content-fullpage" {...provided.droppableProps} ref={provided.innerRef}>
                        {allContentList}
                    </div>               
                 )} 
                </Droppable>
            </DragDropContext> 
            
        </div>
        
        )
    }

    return (
        isLoading ? 
            <div className="content-loading-placeholder">
                Loading content <Loader />
            </div> :
            <div className="content-list">
                <div className="content-list-title">Documents & Resources</div>
                <div className="featuredContent">
                    <ContentBanner canEdit={isContentAdmin} content={banner} />
                    <div className="content-list">
                        <div className="featured-content-title">
                            <i className="fa-solid fa-pepper-hot"></i>
                            Featured Items
                            <a href="/content/all" style={{marginLeft: "auto"}} className="btn btn3">View All</a>
                        </div>
                        {featuredContentDivs}
                        { !isLoading && featuredContent?.length === 0 && <div className="no-content">No content to display</div> }
                    </div>                
                </div>
            </div>
    );
}