import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useStandardQuery = (queryFn, config) => {
    const { user, getAccessTokenSilently } = useAuth0();

    const [data, setData] = useState(null);
    const [status, setStatus] = useState("loading");
    const [error, setError] = useState(null);

    const fetchData = async () => {
        console.log("Fetching data with user:", user);
        try {
            const accessToken = await getAccessTokenSilently();
            const result = await queryFn({...config ?? {}, accessToken});
            setData(result.data);
            setStatus("success");
        } catch (err) {
            setError(err);
            setStatus("error");
        }
    };

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user]);

    return { data, status, error };
}