import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getDbacksPlayers = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/contact/GetDbacksRoster`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const createUpdateContact = async (accessToken, payload, id = null) => {
  const config = {
    url: id ? `${apiServerUrl}/api/contact/update/${id}` : `${apiServerUrl}/api/contact/create`,
    method: id ? "PUT" : "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify(payload)
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getContactById = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/api/contact/${id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {}
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const deleteContactById = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/api/contact/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {}
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getAllContacts = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/contact/getall`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {}
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getAllContactsCSV = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/contact/csv`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {}
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getContactAffiliations = async (config) => {
  console.log("getContactAffiliations", config);
  const requestConfig = {
    url: `${apiServerUrl}/api/contact/affiliations`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${config.accessToken}`,
    },
    data: {}
  };
  console.log("requestConfig", requestConfig);

  const { data, error } = await callExternalApi({ config: requestConfig });

  return {
    data: data || null,
    error,
  };
}