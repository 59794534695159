
export function formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString === null) return phoneNumberString;
    
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
}

export function getFileExtensionFromFileName(fileName) {
  const lastDot = fileName.lastIndexOf('.');
  return fileName.substring(lastDot + 1); 
}

export function doesUserHavePermission(user, permissionName) {
  if (user == null) return false;
  return (user["https://familyapp.dbacks.com/user_authorization"]?.permissions?.includes(permissionName));
}

export function isUserSiteAdmin(user) {
  return doesUserHavePermission(user, "site:admin");
}

export function isUserITAdmin(user) {
  return doesUserHavePermission(user, "IT:admin");
}

export function checkForUserPermissions(permissionsToCheck, user){
  if (user == null) return false;
  const permissionMatches = (user["https://familyapp.dbacks.com/user_authorization"]?.permissions?.filter(permission => permissionsToCheck.includes(permission)));
  return permissionMatches.length > 0;
}

export function checkUserAffiliationForPermissions(affiliation, permissionsToCheck) {
  if (affiliation == null || permissionsToCheck == null) return false;
  console.log(affiliation);
  const permissionMatches = (affiliation?.permissions?.filter(permission => permissionsToCheck.includes(permission)));
  return permissionMatches.length > 0;
}