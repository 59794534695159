import React, { useState, useEffect } from "react";
import { getAllContacts } from "../../services/contact-service";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/contact.css"
import Contact from "./contact";
import Loader from "../navigation/Loader";
import { isUserSiteAdmin, checkUserAffiliationForPermissions } from "../shared/utilities";
import { getContactAffiliationByContactID } from "../../services/affiliation-service";


export default function ContactList({id, showFullVersion = true}) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getAccessTokenSilently, user } = useAuth0();
    const viewerIsAdmin = isUserSiteAdmin(user);
    const [userAffilition, setUserAffiliation] = useState(null);
    
    // const hasContactPermission = checkUserAffiliationForPermissions(userAffilition, ["contacts.view"]);
    
    //TODO: Setting this to true for now until we can get confirmation on the permissions from Diana & Co
    const hasContactPermission = true;


    useEffect(() => {

        async function fetchAffiliation() {
            const accessToken = await getAccessTokenSilently();
            var contactID = user?.memberId ?? null;
            const payload = contactID;
            const affiliationReturned = await getContactAffiliationByContactID(accessToken, payload);            
            setUserAffiliation(affiliationReturned.data);
        }
        fetchAffiliation();

        const retrieveContacts = async () => {
            setLoading(true);
            const token = await getAccessTokenSilently();
            const result = await getAllContacts(token);            
            setContacts(result.data.filter(r => !r.hideContactFromOthers));
            setLoading(false);
        }
        retrieveContacts();
    }, [])

    const contactDivs =contacts?.sort((a,b) => a.firstName.localeCompare(b.firstName)).map(c => 
        <Contact viewerIsAdmin={viewerIsAdmin} contact={c} key={c.id} showFullVersion={false} />
    )

    if(!hasContactPermission) {
        return (
            <div className="contacts-outer">
            <div className="generic-title">D-backs Family Members & Contacts</div>
            { loading ? <Loader /> :
                <div className="no-content">You do not have permission to view this content</div>

            }
        </div>
        )
    }

    return (
        <div className="contacts-outer">
            <div className="generic-title">D-backs Family Members & Contacts</div>
            { loading ? <Loader /> :
                <div className="contact-list">
                    {contactDivs}
                </div>
            }
        </div>
    )
}